import React, { PureComponent } from "react";
import { withTranslation } from 'react-i18next';
import ReactFancyBox from 'react-fancybox';
import PaySection from './PaySection';
import { productData }  from "../productData";
import { productData2 }  from "../productData2";
 class RelatedProduct extends PureComponent {
    checkOrder = () => {
        const { order, product } = this.props;
        try {
            for(let key in order.goods) {
                if(order.goods[key].shopGood === product.id) return true;
            }
        } catch(err) {
            return false;
        }
        return false;
    };

    render() {
				const { t } = this.props;
        const { handleProduct, hide, product} = this.props;
        if (product.description) return null;
        const hideClass = hide ? "hide" : "";
        return (
            <div className={`related-product ${hideClass}`}>
                <div className="related-product-main">
                    <div className="photo-sec">
                        <ReactFancyBox thumbnail={product.images[0]} image={product.images[0]} />
                    </div>
                    <div className="description-related-product">
                        <div>
                            <h4>{t(`good${product.id}.name`)}</h4>
                            <p>{product.note}</p>
                        </div>
                        <div>
                            <PaySection
                                id={product.id}
                                originalPrice={product.price}
																price = {t(`good${product.id}.price`)}
                                checkOrder={this.checkOrder}
                                handleProduct={handleProduct}
																t = {t}
                            />
                            <p className="note-face-device">{t("product_contraindication")}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default  withTranslation()(RelatedProduct);
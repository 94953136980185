import donglePetNetImg from './images/goods/PetNet_box.png';
import donglePetNetImgThumb from './images/goods/thumbnails/PetNet_box.png';

import good_19 from './images/goods/cable_55.png';
import goodThumb_19 from './images/goods/thumbnails/cable_55.png';

import good_20 from './images/goods/cable_75.png';
import goodThumb_20 from './images/goods/thumbnails/cable_75.png';

import good_21 from './images/goods/cable_120.png';
import goodThumb_21 from './images/goods/thumbnails/cable_120.png';

import good_22 from './images/goods/OTG_micro_USB.png';
import goodThumb_22 from './images/goods/thumbnails/OTG_micro_USB.png';

import good_23 from './images/goods/OTG_ Type_C.png';
import goodThumb_23 from './images/goods/thumbnails/OTG_ Type_C.png';

import good_24 from './images/goods/Electrodes_vet_crabs.png';
import goodThumb_24 from './images/goods/thumbnails/Electrodes_vet_crabs.png';

import good_25 from './images/goods/Electrodes_MIO.png';
import goodThumb_25 from './images/goods/thumbnails/Electrodes_MIO.png';

import good_26 from './images/goods/Limb_electrodes.png';
import goodThumb_26 from './images/goods/Limb_electrodes.png';

import good_27 from './images/goods/Case_PETNET.png';
import goodThumb_27 from './images/goods/thumbnails/Case_PETNET.png';

import good_28 from './images/goods/Unispray.png';
import goodThumb_28 from './images/goods/thumbnails/Unispray.png';

const productData2 = {
    '9': {
        description: [
            {
                title: 'description_1_title_good_9',
                text: 'description_1_text_good_9',
            },
            {
                title: 'description_1_title_props_9',
                text: 'description_1_text_props_9',
            },
            {
                title: 'description_1_title_reqs_9',
                text: 'description_1_text_reqs_9',
            },
            {
                title: 'description_1_title_navion_9',
                text: `description_1_text_navion_9`
            },
            {
                title: 'description_1_title_sutable_9',
                text: 'description_1_text_sutable_9'
            }
        ],
        thumbnail: donglePetNetImgThumb,
        images: [donglePetNetImg],
    },
    '19': {
				name: "description_1_title_good_9",
        note: '',
        additional_note: '',
        thumbnail: goodThumb_19,
        images: [good_19],
        alt: "Купить",
    },
    '20': {
				name: "My name 2",
        note: '',
        additional_note: '',
        thumbnail: goodThumb_20,
        images: [good_20],
        alt: "Купить"
    },
    '21': {
				name: "My name 3",
        note: '',
        additional_note: '',
        thumbnail: goodThumb_21,
        images: [good_21],
        alt: "Купить"
    },
    '22': {
        note: '',
				name: "My name 4",
        additional_note: '',
        thumbnail: goodThumb_22,
        images: [good_22],
        alt: "Купить"
    },
    '23': {
        note: '',
        additional_note: '',
        thumbnail: goodThumb_23,
        images: [good_23],
        alt: "Купить"
    },
    '24': {
        note: '',
        additional_note: '',
        thumbnail: goodThumb_24,
        images: [good_24],
        alt: "Купить"
    },
    '25': {
        note: '',
        additional_note: '',
        thumbnail: goodThumb_25,
        images: [good_25],
        alt: "Купить"
    },
    '26': {
        note: '',
        additional_note: '',
        thumbnail: goodThumb_26,
        images: [good_26],
        alt: "Купить"
    },
    '27': {
        note: '',
        additional_note: '',
        thumbnail: goodThumb_27,
        images: [good_27],
        alt: "Купить"
    },
    '28': {
        note: '',
        additional_note: '',
        thumbnail: goodThumb_28,
        images: [good_28],
        alt: "Купить"
    }
};

export {
    productData2
}
import React from "react";
import cn from "classnames";
import s from "./input.module.css";

const Input = (props) => {
  const { input, meta, label, onChange } = props;
  const { valid, submitFailed } = meta;
  return (
    <div className={cn(s.formInput, !valid && submitFailed && s.error)}>
      <label htmlFor={input.name}>{label}</label>{" "}
      <input {...input} id={input.name} />
    </div>
  );
};

export default Input;

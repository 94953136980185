import React from 'react';

import Product from './Product';

const ProductsList = ({allProducts, products, handleProduct, order, setAdditionalGood}) => {
    return products.map((product) =>
        <Product
            key={product.id}
            product={product}
            products={allProducts}
            handleProduct={handleProduct}
            order={order}
            setAdditionalGood={setAdditionalGood}
        />
    );
};

export default ProductsList;
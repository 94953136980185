import * as yup from 'yup';

const schema = yup.object({
  email: yup
    .string()
    .email()
    .required(),
  name: yup
    .string()
    .min(3)
    .required(),
  phone: yup
    .string()
    .matches(/^[0-9+]{6,30}$/)
    .required()
});

export default schema;

import fetch from "isomorphic-fetch";
import { URL_API } from "./config";

const header = {
    credentials: 'include'
};

// add to this.state.products data of the DB
const getGoods = () => {
    return fetch(URL_API + '/store/bsk_getGoods', header)
        .then(response => response.json());
};

const getCurrentOrder = () => {
    return fetch(URL_API + '/store/bsk_getCurrentOrder', header)
        .then(response => response.json());
};

const showCurrentOrder = () => {
    return fetch(URL_API + '/store/bsk_getCurrentOrder', {credentials: 'include'})
        .then(response => response.json());
};

const priceRequestMessage = data => {
    const options = {
      method: 'POST',
      body: `name=${data.name}&email=${data.email ? data.email : ''}&phone=${
        data.phone
      }&goodId=${data.goodId}&goodName=${data.goodName}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      ...header
    };
  
    const url = `${URL_API}/store/priceRequestMessage`;
    return fetch(url, options).then(response => response.json()).catch(e => e);
  };

export {
    getGoods,
    getCurrentOrder,
    showCurrentOrder,
    priceRequestMessage
}
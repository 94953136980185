import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import '../styles/accordion.css';

const DescriptionProduct = (props) => <Accordion><DescriptionRowProduct {...props}/></Accordion>

const DescriptionRowProduct = ({description, t}) =>
    description.map((item, index) =>
        <AccordionItem expanded={index === 0 ? true : false} key={index}>
            <AccordionItemTitle>
                <h4>{t(item.title)}<div className="accordion__arrow" role="presentation"></div></h4>
            </AccordionItemTitle>
            <AccordionItemBody>
                <div dangerouslySetInnerHTML={{__html: t(item.text)}}/>
            </AccordionItemBody>
        </AccordionItem>
    );

export default  withTranslation()(DescriptionProduct);

import React from "react";
import { withTranslation } from "react-i18next";
import { REQUEST_PRICE } from "../constants";

const PaySection = ({
  originalPrice,
  price,
  id,
  checkOrder,
  handleProduct,
  t,
  ...other
}) => {
  return (
    <div className="pay-related-product">
      {/* <div className="price">{FormatePrice(price)} {t("currency")}</div> */}
      {/* <div className="price">{originalPrice} {t("currency")}</div> */}
      <div className="price">{originalPrice} руб</div>
      {/* <div className="price">{price} {t("currency")}</div> */}
      <div
        id={id}
        data-inorder={checkOrder()}
        className="btn-pay"
        onClick={handleProduct}
      >
        {checkOrder() ? t("pay_section_order") : t("pay_section_buy")}
      </div>
      <button
        className="phoneLink d-none btn-request"
        onClick={handleProduct}
        data-button={REQUEST_PRICE}
        data-good-id={id}
      >
        {t('request_a_price')}
      </button>
    </div>
  );
};

// export default PaySection;
export default withTranslation()(PaySection);

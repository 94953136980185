import React, { PureComponent } from "react";
import RelatedProduct from './RelatedProduct';
import doubleRightImg from '../images/double-right.png';
import { withTranslation } from 'react-i18next';
 class RelatedProductsList extends PureComponent {
    state = {
        hideLimit: true
    };

    showAll = () => this.setState({ hideLimit: false });

    render() {
				const { t } = this.props;
        const { hideLimit } = this.state;
        const { order, handleProduct, products} = this.props;

        let result = [];
        let count = 0;
        let hide = false;
        let allRelatedProduct = window.innerWidth > 1500;
        for (let k in products) {
            if (products[k].name !== "ECG Dongle Full" && products[k].name !== "ECG Dongle") {
                if (count > 1 && !allRelatedProduct) hide = hideLimit;
                result.push(
                    <RelatedProduct
                        key={products[k].name}
                        product={products[k]}
                        handleProduct={handleProduct}
                        order={order}
                        hide={hide}
                    />
                );
                count++;
            }
        }

        if (!allRelatedProduct) {
            const hideClass = !hideLimit ? "hide" : "";
            result.push(
                <div className={`related-product showAll ${hideClass}`} onClick={this.showAll} key="showAll">
                    <p>{t("show_all")}</p>
                    <img src={doubleRightImg} alt="double-right"/>
                </div>
            )
        }
        return result;
    }
}

export default withTranslation()(RelatedProductsList);
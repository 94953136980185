import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import FormatePrice from '../utilities/FormatPrice.js';
import 'react-fancybox/lib/fancybox.css';
import ModalOrder from '../components/ModalOrder';
import { productData } from '../productData';
import RelatedProductsList from './RelatedProductsList';
import ProductsList from './ProductsList';
import { URL_API } from "../config";
import { REQUEST_PRICE } from "../constants";
import * as api from '../api';
import '../styles/Catalog.css';
import { hideElement, showElement } from '../toggleElement.js';
import PriceFeedbackDialogForm  from '../containers/PriceFeedbackDialogForm/PriceFeedbackDialogForm.jsx';
class Catalog extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            test: false,
            showModalOrder: false,
            priceFeedbackDialogFormData: {IsOpen: false, goodId: '', goodName: ''},
            dataProcessing: false,
            products: productData,
						// ===
						productData: productData,
						// ===
            updateModalOrder: false,
            order: '',
            currentOrderGoods: [], // for ModalOrder
        };

        this.currentOrder = {};

    }

    componentDidMount()	{
        const { i18n } = this.props;
        i18n.changeLanguage('ru');
					
        const ruBtn = document.querySelector('#ruBtn');
				
        if (ruBtn) {
            ruBtn.onclick = function() {
								let img_ru = document.querySelectorAll(".react-fancybox")[0];
								let img_en = document.querySelectorAll(".react-fancybox")[1];
								img_ru.classList.remove("d-none");
								img_en.style.display = "none";
                i18n.changeLanguage('ru');
								showElement(".btn-pay");
								showElement(".price");
								hideElement(".phoneLink");			
            }.bind(this);
						
        }
        const enBtn = document.querySelector('#enBtn');
        if (enBtn) {
            enBtn.onclick = function() {
								// let img_ru = document.querySelectorAll(".thumbnail img[alt='thumbnail']")[0];
								// let img_en = document.querySelectorAll(".thumbnail img[alt='thumbnail']")[1];
								let img_ru = document.querySelectorAll(".react-fancybox")[0];
								let img_en = document.querySelectorAll(".react-fancybox")[1];
								img_ru.classList.add("d-none");							
								img_en.style.display = "block";
                i18n.changeLanguage('en');
								showElement(".phoneLink");
								hideElement(".btn-pay");
								hideElement(".price");
            }.bind(this);
						
        }

        const arrBtnCart = document.querySelectorAll(".btn-cart");
        for (var i = 0; i < arrBtnCart.length; i++) {
            arrBtnCart[i].onclick = function() {
                this.showCurrentOrder();
            }.bind(this);
        }

        const itemMenuBtnCart = document.querySelector(".item-cart-menu");
        if (itemMenuBtnCart) {
            itemMenuBtnCart.onclick = function() {
                this.showCurrentOrder();
            }.bind(this);
        }

        api.getGoods()
            .then(({goods}) => {
                let currentGoods = this.state.products;
								
                for (let i in goods) {
                    if(typeof(currentGoods[i]) !== 'object') currentGoods[i] = {};
                    currentGoods[i].id = goods[i].id;
                    currentGoods[i].name = goods[i].name;
                    currentGoods[i].price = goods[i].cost;
                }
              this.setState ({ products: currentGoods });
            })
            .then(() => api.getCurrentOrder())
            .then((data) => this.changeOrder(data))
            .then(() => this.setState({ loading: false }))
            .catch((err) => {
                console.error('TypeError', err);
            });
    }
    
    handleProduct = (e) => {
        if (e.target.dataset && e.target.dataset.button === REQUEST_PRICE) {
            const goodId = e.target.dataset.goodId;
            const goodName = this.state.products[goodId].name;

            this.setState({ priceFeedbackDialogFormData: { isOpen: true, goodId, goodName } });
            return;
        } 

        // #73125
        // if (e.target.id === '9') {
        //     window.open('https://ecgdongle.com/petnet', '_blank');
        //     return;
        // }
        e.target.getAttribute('data-inorder') === "true" ?
            window.location = URL_API + "/store" : this.addGood(e.target.id);
    };

    closeModalOrder = () => this.setState({ showModalOrder: false });
    closePriceFeedbackDialogForm = () => this.setState({priceFeedbackDialogFormData: {IsOpen: false, goodId: '', goodName: ''}});
    
    addGood = (id, count = 1) => {
        if (this.currentOrder.order === undefined) this.newOrder();
        // if the goods are already in the basket, then go
        const attachedGoods = this.state.products[id].attachedGoods;
        if (attachedGoods) {
            for (let attachedGoodId in attachedGoods) {
                if (attachedGoods[attachedGoodId].employ) {
                    this.currentOrder.order.goods[attachedGoodId] = {
                        shopGood: attachedGoodId,
                        count: attachedGoods[attachedGoodId].count,
                    }
                }
            }
        }
        this.currentOrder.order.goods[id] = {
            shopGood: id,
            count: count
        };
        this.updateOrder(id);
    };
    
    changeCountGood = (id, count, last) => {
        if (count < 1) {
            this.delGood(id);
        }
        if (this.currentOrder.order === undefined) this.newOrder();
        // if the goods are already in the basket, then go
        this.currentOrder.order.goods[id] = {
            shopGood: id,
            count: count 
        };
        if (last) {
            this.updateOrder(id);
        } else {
            this.updateOrder();
        }
    };

    delGood = (id) => {
        this.currentOrder.order.goods[id] = {
            shopGood: id,
            count: 0 
        };
        this.updateOrder();
    };

    changeOrder = (data) => {
        if (data.order) this.updateCountCart(data.order.goods);
        this.currentOrder = data;
        this.setState({ order: data.order });
    };
       
    showCurrentOrder = (idGood = false) => {
        api.showCurrentOrder()
            .then((data) => {
                let goods = data.order.goods;
                let currentOrderGoods = [];
                let lastGood = {};
                let addLastGood = false;
                for (let key in goods) {
                    let good = {};
                    let id = goods[key].shopGood;
                    let count = goods[key].count;
                    good.id = id;
                    good.image = this.state.products[id].thumbnail;
                    good.name = this.state.products[id].name;
                    good.count = count;
                    good.amount = FormatePrice(count * this.state.products[id].price) + ' руб';
                    if (key === idGood) {
                        lastGood = good;
                        addLastGood = true
                    } else {
                        currentOrderGoods.push(good);
                    }
                }
                if (addLastGood) {
                    currentOrderGoods.push(lastGood);
                }

                if (currentOrderGoods.length === 0 && !idGood) {
                    this.closeModalOrder();
                } else if (currentOrderGoods.length > 0) {
                    // for updating data in a modal window
                    this.setState({showModalOrder: false});
                    this.setState({
                        showModalOrder: true,
                        currentOrderGoods: currentOrderGoods,
                        updateModalOrder: !idGood,
                        dataProcessing: false,
                    })
                }
            }).catch((err) => {
                console.error('TypeError', err);
                window.location = URL_API + "/store/preorderPetnetECG"
            });
    };
    
    newOrder = () => {
        this.currentOrder.order = {};
        this.currentOrder.order.name = '';
        this.currentOrder.order.email = '';
        this.currentOrder.order.phone = '';
        this.currentOrder.order.pickup = '2';
        this.currentOrder.order.deliveryService = '';
        this.currentOrder.order.city = '';
        this.currentOrder.order.cityId = '';
        this.currentOrder.order.pvzCode = '';
        this.currentOrder.order.address = '';
        this.currentOrder.order.comment = '';
        this.currentOrder.order.traderCode = '';
        this.currentOrder.order.payment = '1';
        this.currentOrder.order.goods = {};   
    };
    
    // add goods to cart (save order)
    updateOrder = (idGood) => {
        this.setState({ dataProcessing: true });
        let order =  this.currentOrder.order;
        let goods = order.goods;
        let data =  "name=" + order.name +
                    "&email=" + order.email +
                    "&phone=" + order.phone +
                    "&pickup=" + order.pickup +
                    "&pvzCode=" + order.pvzCode +
                    "&deliveryService=" + order.deliveryService +
                    "&city=" + order.post_city +
                    "&cityId=" + order.post_cityId +
                    "&address=" + order.post_address +
                    "&comment=" + order.comment +
                    "&traderCode=" + order.traderCode +
                    "&payment=" + order.payment;
        for(var key in  goods){
            data += "&goods[" + key +"][id]=" + goods[key].shopGood;
            data += "&goods[" + key +"][count]=" + goods[key].count;
        }
        
        let options = {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            credentials: 'include',
        };
        
        let url = URL_API + '/store/bsk_saveOrder';
        fetch(url, options)
        .then(response => response.json())
        .then(() => api.getCurrentOrder())
        .then((data) => this.changeOrder(data))
        .then(() => {
            this.showCurrentOrder(idGood);
            this.updateCountCart(this.state.order.goods);
        }).catch((err) => {
            console.log(err);
        });
    };
    
    updateCountCart = (goods) => {
        let count = 0;
        for (let key in goods) {
            count += Number(goods[key]['count']);
        }
        let arrCart = document.querySelectorAll("div.btn-cart span");
        for (var i = 0; i < arrCart.length; i++) {
            arrCart[i].innerHTML = count;
        }
    };
    
    setAdditionalGood = (goodId, attachedGoodId, employ) => {
        let products = this.state.products;
        products[goodId].attachedGoods[attachedGoodId].employ = employ;
        this.setState({ products: products });
        if (this.state.order.goods[goodId]) {
            // The main product in the basket, it means you need to add or remove the attached product
            if (employ) {
                this.addGood(attachedGoodId);
            } else {
                this.delGood(attachedGoodId);
            }
        }
    };

    render() {
        const { t } = this.props;				
        const {
            updateModalOrder,
            dataProcessing,
            loading,
            products,
            priceFeedbackDialogFormData,
            showModalOrder,
            order,
          } = this.state;
        if (loading) return <div className="catalog">Loading...</div>;
        let productsArray = Object.keys(products).map(function(e) {
            return products[e];
        });

        let mainProducts = productsArray.filter((item) => item.id === '9');
        let relatedProductsIdArr = ['19', '20', '21', '23', '24', '25', '26', '27', '28'];
        let relatedProducts = productsArray.filter((item) => relatedProductsIdArr.includes(item.id));

        return (
            <div className="catalog">
                <PriceFeedbackDialogForm
                    data={priceFeedbackDialogFormData}
                    onRequestClose={this.closePriceFeedbackDialogForm}
                />
                <ModalOrder
                    showModalOrder={showModalOrder}
                    closeModalOrder={this.closeModalOrder}
                    currentOrderGoods={this.state.currentOrderGoods}
                    delGood={this.delGood}
                    formОrder={this.handleProduct}
                    updateModalOrder={updateModalOrder}
                    changeCountGood={this.changeCountGood}
                    dataProcessing={dataProcessing}
                />
                <div className="products">
                    <ProductsList
                        products={mainProducts}
                        allProducts={products}
                        handleProduct={this.handleProduct}
                        order={order}
                        setAdditionalGood={this.setAdditionalGood}
                    />
                </div>
                <div className="related-products">
                    <h3>{t('additional_electrodes')}</h3>
                    <div className="blocks">
                        <RelatedProductsList
                            products={relatedProducts}
                            handleProduct={this.handleProduct}
                            order={this.state.order}
                            translate={this.translate}/>
                    </div>
                </div>
            </div>
        );
    }
}
    
export default withTranslation()(Catalog);

 
function hideElement(className){
	let allElems = document.querySelectorAll(className);

	allElems.forEach((btn)=>{
		btn.classList.add("d-none");
		btn.classList.remove("d-block");
	});
}


function showElement(className){
	let allElems = document.querySelectorAll(className);

	allElems.forEach((btn)=>{
		btn.classList.remove("d-none");
		btn.classList.add("d-block");
	});
}




export{
	hideElement,
	showElement
}
 
 
import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import PaySectionShelter from "./PaySectionShelter.js";
import DescriptionProduct from './DescriptionProduct';
import ReactFancyBox from 'react-fancybox';

class Product extends Component {
    checkOrder = () => {
        const { order, product } = this.props;
        try {
            for(let key in order.goods) {
                if(order.goods[key].shopGood === product.id) return true;
            }
        } catch(err) {
            return false;
        }
        return false;
    };
    
    checkAttachedlGoods = (id) => {
        const { order, product } = this.props;
        // if the main item in the basket is missing, then check boxes
        if (order != undefined && order.goods[product.id]) {
            return !!order.goods[id];
        } else {
            return product.attachedGoods[id].employ;
        }
    };

    setAttachedlGoods = (id) => {
        const { setAdditionalGood, product } = this.props;
        const emploe = !this.checkAttachedlGoods(id);
        setAdditionalGood(product.id, id, emploe);
    };

    render() {
        const { t } = this.props;
        const { handleProduct, product, products} = this.props;
        const checkOrder = this.checkOrder();
        const attachedGoodId_7 = 7;
        const priceAttachedGood_7 = products[attachedGoodId_7].price;
        const attachedGoodId_8 = 8;
        const priceAttachedGood_8 = products[attachedGoodId_8].price;

        return (
            <div className="product">
                <div>
                    <div>
                        <header>
                            <h3>{t(`name_good_${product.id}`)}</h3>
                        </header>
                        <div className="product-content">
                            <div className="description-sec">
                                <DescriptionProduct description={product.description} />
                            </div>
                            <div className="photo-sec">
                                <ReactFancyBox thumbnail={product.thumbnail} image={product.images[0]} />
                                <ReactFancyBox thumbnail={product.thumbNail_EN} image={product.images_EN[0]} />
                                <div className="right-header">
                                    <h3>{product.name}</h3>
                                    <PaySectionShelter
                                        id={product.id}
                                        price={product.price}
                                        checkOrder={this.checkOrder}
                                        handleProduct={handleProduct}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <PaySectionShelter
                        id={product.id}
                        price={product.price}
                        checkOrder={this.checkOrder}
                        handleProduct={handleProduct}
                    />
                    <p className="note-face-device">{t('product_warning')}</p>
                </div>
                <div className="vertical-note"><p>{t('product_contraindication')}</p></div>
            </div>
        );
    }
}

export default  withTranslation()(Product);

import React from "react";
import { withTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import Input from "../../components/Input/Input.jsx";
import schema from "./schema.js";
import validate from "../../utilities/validate.js";
import ModalWindow from "../../components/ModalWindow/ModalWindow.jsx";
import { priceRequestMessage } from "../../api.js";
import s from "./PriceFeedbackDialogForm.module.css";

const PriceFeedbackDialogForm = ({ t, data, onRequestClose }) => {
  const { goodId, goodName, isOpen } = data;

  const normalizePhone = (value) => {
    if (!value) return value;
    const phone = value.replace(/[^\d+]/g, "");
    return `${phone.substring(0, 1)}${phone.slice(1).replace(/\+/g, "")}`;
  };

  const onSubmit = (value) => {
    return priceRequestMessage(value)
      .then((response) => {
        if (response.success) {
          setTimeout(() => onRequestClose(), 2000);
          return true;
        } else {
          return { error: true };
        }
      })
      .catch((e) => ({ error: e }));
  };

  return (
    <ModalWindow
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={t("contact_form_title")}
    >
      <Form
        onSubmit={onSubmit}
        validate={(value) =>
          validate({ ...value, phone: normalizePhone(value.phone) }, schema, {})
        }
      >
        {({
          handleSubmit,
          submitSucceeded,
          hasSubmitErrors,
          submitting,
          modifiedSinceLastSubmit,
        }) => {
          const notModifiedAndSuccess =
            submitSucceeded && !modifiedSinceLastSubmit;
          const notModifiedAndError =
            hasSubmitErrors && !modifiedSinceLastSubmit;

          return (
            <form onSubmit={handleSubmit}>
              <Field
                autoFocus
                name="name"
                label={`${t("name")} *`}
                component={Input}
              />
              <Field
                name="phone"
                label={`${t("phone")} *`}
                component={Input}
                type="tel"
                format={normalizePhone}
              />
              <Field name="email" label="Email *" component={Input} />
              <Field
                name="goodId"
                component="input"
                type="hidden"
                defaultValue={goodId}
              />
              <Field
                name="goodName"
                component="input"
                type="hidden"
                defaultValue={goodName}
              />
              <div className={s.actionBox}>
                <button
                  className="btn-request"
                  type="submit"
                  disabled={
                    submitting || notModifiedAndSuccess || notModifiedAndError
                  }
                >
                  {t("send_request")}
                </button>
              </div>
              {notModifiedAndSuccess && (
                <div className={s.successMessage}>
                  {t("request_success_message")}
                </div>
              )}
              {notModifiedAndError && (
                <div className={s.errorMessage}>
                  {t("request_error_message")}
                </div>
              )}
            </form>
          );
        }}
      </Form>
    </ModalWindow>
  );
};

export default withTranslation()(PriceFeedbackDialogForm);

import React from 'react';
import FormatePrice from "../utilities/FormatPrice";
import { URL_API } from "../config";
import { withTranslation } from 'react-i18next';
import { REQUEST_PRICE } from '../constants';

const PaySectionShelter = ({ price, id, checkOrder, handleProduct, t }) => {
    return (
        <div className="pay-sec">
            <span className="price">{FormatePrice(price)} {t("currency")}</span>
            <div
                id={id}
                data-inorder={checkOrder()}
                className="btn-pay"
                onClick={handleProduct}>
                {checkOrder() ? t("pay_section_order") : t("pay_section_buy")}
                {/*#73125 {t("order")} */}
            </div>
            <a className="btn-pay" href={`${URL_API}/store/shelterPetnetECG`}>{t("pay_section_shelter_buy")}</a>
            <button
                className="phoneLink d-none btn-request"
                onClick={handleProduct}
                data-button={REQUEST_PRICE}
                data-good-id={id}
            >
                {t('request_a_price')}
            </button>
        </div>
    );
};

// export default PaySection;
export default withTranslation()(PaySectionShelter);
import React from "react";
import Modal from "react-modal";
import s from "./ModalWindow.module.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
    border: "none",
    backgroundColor: "rgba(0,0,0,0)",
    padding: "10px",
    maxHeight: "100vh",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.75)",
    zIndex: "1000",
  },
};

Modal.setAppElement("#catalog");

const ModalWindow = ({ isOpen, onRequestClose, title, children }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <div className={s.main}>
        <button className={s.closeButton} onClick={onRequestClose}>
          ×
        </button>
        <h2 className={s.title}>{title}</h2>
        {children}
      </div>
    </Modal>
  );
};

export default ModalWindow;


import React from 'react';
import preloader from '../images/preloader.gif';
 
class Counter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.good.count,
        };
    }
    
    changeCount = (event) => {
        const id = event.target.dataset.id;
        const value = event.target.dataset.value;
        const count = (+this.state.value) + (+value);
        if (count > 999) {
            return;
        }
        const last = (event.target.dataset.last === "0" || count < 1) ? false : true;
        this.props.changeCountGood(id, count, last);
    };
    
    changeCountGood = (event) => {
        let value = Number(event.target.value);
        if (value >= 0) {
            if (value > 999) {
                value = 999;
            }
            this.setState({value: value});
        }
    };
    
    sendChangeCountGood = (event) => {
        const count = event.target.value;
        if (count === this.props.good.count) {
            return;
        }
        const id = event.target.dataset.id;
        const last = (event.target.dataset.last === "0" || count < 1) ? false : true;
        this.props.changeCountGood(id, count, last);
    };
    
    pressBtn = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.sendChangeCountGood(event);
        }
    };
    
    lossFocus = (event) => {
        this.sendChangeCountGood(event);
    };


    render() {
        const id = this.props.good.id;

        return (
                <div className="counter-section">
                    {(this.props.dataProcessing) ? <div className="counter-preloader"><img className="preloader-img" alt="preloader-img" src={preloader} /></div> : null}
                        <div className="counter">
                            <div
                                className="left-btn"
                                data-last={this.props.last}
                                data-id={id}
                                data-value="-1"
                                onClick={this.changeCount}
                            >
                                -
                            </div>
                            <div>
                                <input
                                    className="count"
                                    data-last={this.props.last}
                                    data-id={id}
                                    name="count"
                                    value={this.state.value}
                                    onChange={this.changeCountGood}
                                    onKeyDown={this.pressBtn}
                                    onBlur={this.lossFocus}
                                />
                            </div>
                            <div
                                className="right-btn"
                                data-last={this.props.last}
                                data-id={id}
                                data-value="1"
                                onClick={this.changeCount}
                            >
                                +
                            </div>
                        </div>
                  </div>
                );
    }
}

export default Counter;
import React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import '../styles/ModalWindow.css';
import success from '../images/success.png';
import Counter from './ModalOrderCounter';
 
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    overflow              : 'auto',
    border                : 'none',
    backgroundColor       : 'rgba(0,0,0,0)',
    padding               : '10px',
    maxHeight             : '100vh',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.75)',
    zIndex: '1000',
  },
};
 
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#catalog')
 
class ModalWindow extends React.Component {
  constructor(props) {
    super(props);
 
    this.state = {
      modalIsOpen: false
    };
 
    this.afterOpenModal = this.afterOpenModal.bind(this);
  }
 
 
  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
 
  closeModal() {
    this.setState({modalIsOpen: false});
  }
  
  changeCountTest = (event) => {
      const id = event.target.dataset.id;
      const item = event.target.dataset.item;
      const value = event.target.dataset.value;
      const order = this.props.currentOrderGoods;
      const count = (+order[item].count) + (+value);
      const last = (event.target.dataset.last === "0" || count === 0) ? false : true;
      this.props.changeCountGood(id, count, last);
  }
 
    render() {
        return (
            <div>
                <Modal
                  isOpen={this.props.showModalOrder}
                  onAfterOpen={this.afterOpenModal}
                  onRequestClose={this.props.closeModalOrder}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                    <div className="modalContent">
                        <header>
                            <div className={classNames({"hide": this.props.updateModalOrder})}>
                                <img src={success} alt="success"/><span className="">Товар добавлен в корзину</span>
                            </div>
                            <div className={classNames({"hide": !this.props.updateModalOrder})}>
                                <span className="">Ваш заказ</span>
                            </div>
                            <div className="btn-close" onClick={this.props.closeModalOrder}>×</div>
                        </header>
                        {(this.props.updateModalOrder) ? '' :
                                <LastGood 
                                    goods={this.props.currentOrderGoods}
                                    delGood={this.props.delGood}
                                    changeCountGood={this.props.changeCountGood}
                                    dataProcessing={this.props.dataProcessing}
                                />}
                        <CurrentOrder
                            goods={this.props.currentOrderGoods}
                            delGood={this.props.delGood}
                            updateModalOrder={this.props.updateModalOrder}
                            changeCountGood={this.props.changeCountGood}
                            dataProcessing={this.props.dataProcessing}
                        />
                        <div className="control">
                            <div
                                className="btn"
                                onClick={this.props.closeModalOrder}
                            >
                                Вернуться к списку товаров
                            </div>
                            <div
                                className="btn"
                                onClick={this.props.formОrder}
                                data-inorder="true"
                            >
                                Оформить заказ
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const LastGood = ({goods, delGood, changeCountGood, dataProcessing}) => {
    if (goods.length < 1) {
        return null;
    }
    const good = goods.slice(-1)[0];
    return (
        <div className="product-sec">
            <img src={good.image} alt=""/>
            <div className="info">
                <div>{good.name}</div>
                <div>{good.amount}</div>
                <Counter
                    changeCountGood={changeCountGood}
                    dataProcessing={dataProcessing}
                    good={good}
                    last ={1}
                />
            </div>
            <div className="delete-product" onClick={() => delGood(good.id)}>×</div>
        </div>
    )
}

const CurrentOrder = ({goods, delGood, updateModalOrder, changeCountGood, dataProcessing}) => {
    if (goods.length <= 1 && !updateModalOrder) {
        return null;
    }
    if (!updateModalOrder) {
        goods = goods.slice(0, -1);
    }
    return (
        <div>
        {(updateModalOrder) ? '' : <p className="title-product-item">Так же в корзине</p>}
            {goods.map((good, i) =>
                <div key={i} className="product-item">
                    <div className="name">{good.name}</div>
                    <Counter
                        changeCountGood={changeCountGood}
                        dataProcessing={dataProcessing}
                        good={good}
                        last ={0}
                    />
                    <div className="price">{good.amount}</div>
                    <div className="delete-product" onClick={() => delGood(good.id)}>×</div>
                </div>
            )}
        </div>
    )
}

export default ModalWindow;